<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="完成标志" prop="eventstatus" style="width: 15%">
              <a-select v-model="queryParams.recordstatus">
                <a-select-option :value="1">处理中</a-select-option>
                <a-select-option :value="2">待验证</a-select-option>
                <a-select-option :value="3">已完成</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="品质改进单号" prop="improveordernum" style="width: 15%">
              <a-input v-model="queryParams.improveordernum"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="width: 20%;float:right;">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
          </a-form-model>
        </div>
      </div>
    </div>
    <div v-if="showTotal" class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <a-row>
          <a-col :span="6"><a-statistic title="公司/项目" :value="statistics.userdepname" style="margin-right: 10px" /></a-col>
          <a-col :span="6"><a-statistic title="品质改进单数量" :value="statistics.total" style="margin-right: 10px" /></a-col>
          <a-col :span="6"><a-statistic title="已完成数量" :value="statistics.finishtotal" style="margin-right: 10px" /></a-col>
          <a-col :span="6"><a-statistic title="完成率" :value="statistics.rate?statistics.rate+'%':0+'%'" style="margin-right: 10px" /></a-col>
        </a-row>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="status" slot-scope="value">
             <a-tag :color="value=='已完成'?'green':value=='待验证'?'yellow':'red'">{{value}}</a-tag>
          </span>
          <span slot="totaltime" slot-scope="value,record">
             <span :style="record.totaltimes<0?{'color':'red'}:{'color':''}">{{value?value:''}}</span>
          </span>
          <span slot="responsetime" slot-scope="value,record">
             <span :style="record.responsetimes<0?{'color':'red'}:{'color':''}">{{value?value:''}}</span>
          </span>
          <span slot="starttime" slot-scope="value,record">
             <span :style="record.starttimes<0?{'color':'red'}:{'color':''}">{{value?value:''}}</span>
          </span>
          <span slot="finishtime" slot-scope="value,record">
             <span :style="record.finishtimes<0?{'color':'red'}:{'color':''}">{{value?value:''}}</span>
          </span>
          <span slot="deviationtime" slot-scope="value,record">
             <span :style="record.deviationtimes<0?{'color':'orange'}:{'color':''}">{{value?value:''}}</span>
          </span>
          <span slot="followtime" slot-scope="value,record">
             <span :style="record.followtimes<0?{'color':'red'}:{'color':''}">{{value?value:''}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.improveorderid">详情</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import pagination from '@/mixins/pagination'
import {getItemFromArrayByKey, getTimeRange} from 'U'
import {mapState} from "vuex";
import deptselect from "@/mixins/deptselect";
import {
  exportImproveOrderByCondition,
  exportImproveOrderExcel,
  getImproveOrderListByCondition,
  getImproveOrderStatistics
} from "A/patrol";
import moment from "moment";
import {processTypes} from "@/json/wlwhistory";
import addOrEditModal from "V/supervise/improveOrder/addOrEditModal";
export default {
  name:'improveOrderReport',
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      showAdvanced: false,
      checkRecordsVisible:false,
      userdepidCascaderSelected: [],
      queryInstallDate:null,
      processTypes,
      queryParams: {
        monitorpointname:'',
        improveordernum:'',
        orderstime:'',
        recordstatus:'',
        userdepsid:'',
      },
      statistics:{
        userdepname:'',
        total:'',
        finishtotal:'',
        rate:'',
      },
      tableColumns: [
        {
          title: '公司/项目',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '品质改进单号',
          dataIndex: 'improveordernum',
          key: 'improveordernum',
          width:100,
          ellipsis: true,
        },
        {
          title: '完成标志',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
          width:70,
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '总用时',
          dataIndex: 'totaltime',
          key: 'totaltime',
          ellipsis: true,
          scopedSlots: { customRender: 'totaltime' },
        },
        {
          title: '响应用时',
          dataIndex: 'responsetime',
          key: 'responsetime',
          ellipsis: true,
          scopedSlots: { customRender: 'responsetime' },
        },
        {
          title: '启动用时',
          dataIndex: 'starttime',
          key: 'starttime',
          ellipsis: true,
          scopedSlots: { customRender: 'starttime' },
        },
        {
          title: '完成用时',
          dataIndex: 'finishtime',
          key: 'finishtime',
          ellipsis: true,
          scopedSlots: { customRender: 'finishtime' },
        },
        {
          title: '计划用时偏差',
          dataIndex: 'deviationtime',
          key: 'deviationtime',
          ellipsis: true,
          scopedSlots: { customRender: 'deviationtime' },
        },
        {
          title: '跟进用时',
          dataIndex: 'followtime',
          key: 'followtime',
          ellipsis: true,
          scopedSlots: { customRender: 'followtime' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      showTotal:false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '品质改进分析报表';
    },
    breadcrumb() {
      return [
        {
          name: '工作查阅与报告',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.getTableData();
    this.initDeptOptionsAll();
  },
  methods: {
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
      this.queryParams.recordstatus="";
      this.userdepidCascaderSelected=[];
      this.queryInstallDate=[];
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      if(this.userInfo.userdepid==0&&!this.queryParams.userdepsid){
        this.queryParams.userdepsid=143
      }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      exportImproveOrderByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
          this.getImproveOrderStatistics()
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getImproveOrderStatistics(){
      if(!this.queryParams.userdepsid){
        this.queryParams.userdepsid=this.userInfo.userdepid
      }else if(this.userInfo.userdepid==0&&!this.queryParams.userdepsid){
        this.queryParams.userdepsid=143
      }
      getImproveOrderStatistics({...this.queryParams}).then(res=>{
        if (res && res.returncode == '0') {
          if(res.item.length>0){
            this.statistics=res.item[0]
            this.showTotal=true
          }else{
            this.statistics={
              userdepname:'',
              total:'',
              finishtotal:'',
              rate:'',
            }
          }
        }
      })
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      getImproveOrderListByCondition({improveorderid:value}).then(res => {
        if (res && res.returncode == '0') {
          this.modalDetailData = res.item[0];
          this.modalDetailData.improveorderid=value
          this.modalDetailData.orderstime=this.modalDetailData.orderstime?moment(this.modalDetailData.orderstime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalDetailData.analysistime=this.modalDetailData.analysistime?moment(this.modalDetailData.analysistime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalDetailData.improvetime=this.modalDetailData.improvetime?moment(this.modalDetailData.improvetime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalDetailData.scheduledtime=this.modalDetailData.scheduledtime?moment(this.modalDetailData.scheduledtime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalDetailData.verificationtime=this.modalDetailData.verificationtime?moment(this.modalDetailData.verificationtime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
          this.modalVisible = true;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let improveorderid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'improveorderid', improveorderid);
      this.showModal(type, improveorderid, record)
    },
    exportModal() {
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportImproveOrder()
      }).catch(()=>{
      });
    },
    exportImproveOrder(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportImproveOrderExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    }
  }
}
</script>